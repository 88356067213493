import React from "react"
import { Link, graphql } from "gatsby"
import galleryStyles from "./galleryPage.module.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Layout from "../components/layout"
import Head from "../components/head"
import Img from "gatsby-image/withIEPolyfill"
import { BLOCKS } from "@contentful/rich-text-types"

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "gallery" }) {
      title
      date
      body {
        json
      }
    }
    allSitePage(filter: { context: { tag: { eq: "gallery" } } }) {
      edges {
        node {
          path
          context {
            id
            title
            date
            slug
            cover {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`
const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const alt = node.data.target.fields.title["en-US"]
      const url = node.data.target.fields.file["en-US"].url
      return <img alt={alt} src={url} />
    },
  },
}

const List = props => {
  return (
    <Layout>
      <Head title={props.data.contentfulPage.title} />

      <section className="hero is-small is-bold content">
        <div className="container">
          <div className="hero-body">
            <div className="container has-text-centered">
              {props.data.allSitePage.edges.length > 0
                ? documentToReactComponents(
                    props.data.contentfulPage.body.json,
                    options
                  )
                : "Няма нищо тук"}
            </div>
          </div>

          <div className="columns is-multiline is-mobile is-vcentered  is-centered">
            {props.data.allSitePage.edges.map(edge => {
              return (
                <div
                  key={edge.node.context.id}
                  className=" column is-one-quarter-desktop is-two-thirds-mobile is-two-thirds-tablet"
                >
                  <div
                    style={{ height: "350px", marginBottom: "0" }}
                    className={`card columns is-multiline ${galleryStyles.isShady}`}
                  >
                    <div
                      className=" column is-12"
                      style={{ overflow: "hidden" }}
                    >
                      <Link to={edge.node.path}>
                        {edge.node.context.cover.src ? (
                          <Img
                            style={{ height: "200px" }}
                            fluid={edge.node.context.cover}
                          />
                        ) : (
                          <img
                            style={{ height: "200px", objectFit: "cover" }}
                            src="https://source.unsplash.com/collection/1922729/720x240"
                            alt=""
                          />
                        )}
                      </Link>
                    </div>
                    <div className=" column is-12">
                      <h5 className="title is-6 has-text-centered">
                        <Link
                          key={edge.node.context.id}
                          className="is-uppercase"
                          to={edge.node.path}
                        >
                          {edge.node.context.title}
                        </Link>
                        <br />
                        <span className={`is-size-6 ${galleryStyles.span}`}>
                          {edge.node.context.date}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default List
